import { motion } from 'framer-motion'
import Link from 'next/link'
import React, { useEffect, useRef, useState } from 'react'

import { LazyImage } from '../lazy-image'

type Props = {
  locationName: string
  featureImage: string
  logoImage: string
  animate?: boolean
  children?: React.ReactNode
  hasDrawer?: boolean
}

const ANIMATION_COOKIE_NAME = 'hero_animation_timestamp'
const ANIMATION_COOLDOWN = 10 * 60 * 1000 // 10 mins in milliseconds
const MIN_HEADER_HEIGHT = '15rem' // Minimum header height
const MAX_HEADER_HEIGHT = '20rem' // Maximum header height for non-mobile

export const Hero = ({
  locationName,
  featureImage,
  logoImage,
  animate: animateProp = true,
  children,
  hasDrawer = false,
}: Props) => {
  const [isMobile, setIsMobile] = useState(true)
  const [animationComplete, setAnimationComplete] = useState(false)
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [bgImageLoaded, setBgImageLoaded] = useState(false)
  const [logoImageLoaded, setLogoImageLoaded] = useState(false)
  const [canAnimate, setCanAnimate] = useState(true)
  const [contentHeight, setContentHeight] = useState(0)
  const contentRef = useRef<HTMLDivElement>(null)

  // Check animation cooldown
  useEffect(() => {
    if (ANIMATION_COOLDOWN === 0) {
      setCanAnimate(true)
      return
    }

    const lastAnimationTime = parseInt(localStorage.getItem(ANIMATION_COOKIE_NAME) || '0')
    const currentTime = Date.now()

    if (currentTime - lastAnimationTime > ANIMATION_COOLDOWN) {
      setCanAnimate(true)
      localStorage.setItem(ANIMATION_COOKIE_NAME, currentTime.toString())
    } else {
      setCanAnimate(false)
    }
  }, [])

  // Measure content height
  useEffect(() => {
    if (contentRef.current) {
      const updateContentHeight = () => {
        const height = contentRef.current?.offsetHeight || 0
        setContentHeight(height)
      }

      updateContentHeight()
      window.addEventListener('resize', updateContentHeight)
      return () => window.removeEventListener('resize', updateContentHeight)
    }
  }, [children])

  // Check for mobile screen size
  useEffect(() => {
    if (typeof window === 'undefined') return

    const checkMobile = () => setIsMobile(window.innerWidth < 640)
    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  // Only animate on mobile if animation is enabled and cooldown is complete
  const shouldAnimate = isMobile && animateProp && canAnimate

  // Calculate header height
  const calculateHeaderHeight = () => {
    if (typeof window === 'undefined') return MIN_HEADER_HEIGHT

    // For non-mobile screens, use max height
    if (!isMobile) {
      return MAX_HEADER_HEIGHT
    }

    const viewportHeight = window.innerHeight

    // If we have a drawer in cinematic mode, reduce the height
    if (hasDrawer && animateProp) {
      return `${Math.max(
        parseInt(MIN_HEADER_HEIGHT),
        viewportHeight * 0.45 // Use 45% of viewport height for hero when drawer is present
      )}px`
    }

    const targetHeight = `${viewportHeight - contentHeight}px`

    // Ensure header doesn't go below minimum height
    if (viewportHeight - contentHeight < parseInt(MIN_HEADER_HEIGHT)) {
      return MIN_HEADER_HEIGHT
    }

    return targetHeight
  }

  // Handle image loading and animation timing
  useEffect(() => {
    if (!shouldAnimate) {
      setImagesLoaded(true)
      setAnimationComplete(true)
      return
    }

    if (bgImageLoaded && logoImageLoaded) {
      setImagesLoaded(true)
      const timer = setTimeout(() => {
        setAnimationComplete(true)
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [bgImageLoaded, logoImageLoaded, shouldAnimate])

  // Preload images
  useEffect(() => {
    if (!shouldAnimate) {
      setBgImageLoaded(true)
      if (logoImage) {
        const logo = new Image()
        logo.onload = () => setLogoImageLoaded(true)
        logo.src = logoImage
      } else {
        setLogoImageLoaded(true)
      }
      return
    }

    if (featureImage) {
      const bg = new Image()
      bg.onload = () => setBgImageLoaded(true)
      bg.src = featureImage
    } else {
      setBgImageLoaded(true)
    }

    if (logoImage) {
      const logo = new Image()
      logo.onload = () => setLogoImageLoaded(true)
      logo.src = logoImage
    } else {
      setLogoImageLoaded(true)
    }
  }, [featureImage, logoImage, shouldAnimate])

  if (!shouldAnimate) {
    return (
      <div className="w-full">
        <Link href={`/menu/`}>
          <div className="relative" style={{ height: calculateHeaderHeight() }}>
            {/* Static background */}
            <div className="absolute inset-0 sm:rounded-lg overflow-hidden">
              {featureImage ? (
                <div className="h-full w-full">
                  <LazyImage src={featureImage} alt={''} />
                </div>
              ) : (
                <div className="h-full w-full object-cover" />
              )}
            </div>

            {/* Content container with animated logo */}
            <div className="relative h-full flex justify-center items-center">
              {logoImage ? (
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4 }}
                  className="absolute max-h-32 max-w-[240px]"
                  src={logoImage}
                  alt={''}
                />
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4 }}
                  className="text-4xl text-lg-bold text-center text-white max-w-[240px]">
                  {locationName}
                </motion.div>
              )}
            </div>
          </div>
        </Link>
        <div ref={contentRef} className={'flex items-center align-center justify-center p-8'}>
          {children}
        </div>
      </div>
    )
  }

  // Mobile animation version
  return (
    <div className="w-full">
      <Link href={`/menu/`}>
        <motion.div
          initial={{ height: '100vh' }}
          animate={{
            height: animationComplete ? calculateHeaderHeight() : '100vh',
          }}
          transition={{
            duration: 0.8,
            ease: 'easeInOut',
            delay: animationComplete ? 0 : 1.2,
          }}
          className="relative">
          {/* Background image container */}
          <motion.div
            className="absolute inset-0 sm:rounded-lg overflow-hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: imagesLoaded ? 1 : 0 }}
            transition={{ duration: 0.4 }}>
            {featureImage ? (
              <div className="h-full w-full">
                <LazyImage src={featureImage} alt={''} />
              </div>
            ) : (
              <div className="h-full w-full object-cover" />
            )}
          </motion.div>

          {/* Content container */}
          <div className="relative h-full flex justify-center items-center">
            {logoImage ? (
              <motion.img
                initial={{
                  scale: 1.5,
                  y: 20,
                  opacity: 0,
                }}
                animate={{
                  scale: animationComplete ? 1 : 1.5,
                  y: animationComplete ? 0 : 20,
                  opacity: imagesLoaded ? 1 : 0,
                }}
                transition={{
                  opacity: { duration: 0.4, delay: 0.5 },
                  scale: { duration: 0.8, delay: animationComplete ? 0 : 0.5 },
                  y: {
                    duration: animationComplete ? 0.8 : 0.4,
                    delay: animationComplete ? 0 : 0.5,
                    ease: 'easeOut',
                  },
                }}
                className="absolute max-h-32 max-w-[240px]"
                src={logoImage}
                alt={''}
              />
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{
                  opacity: imagesLoaded ? 1 : 0,
                  y: 0,
                }}
                transition={{
                  opacity: { duration: 0.4, delay: 0.5 },
                  y: { duration: 0.4, delay: 0.5 },
                }}
                className="text-4xl text-lg-bold text-center text-white max-w-[240px]">
                {locationName}
              </motion.div>
            )}
          </div>
        </motion.div>
      </Link>
      <motion.div
        ref={contentRef}
        className={'flex items-center align-center justify-center p-8'}
        initial={{ opacity: 0 }}
        animate={{ opacity: animationComplete ? 1 : 0 }}
        transition={{ duration: 0.4 }}>
        {children}
      </motion.div>
    </div>
  )
}

export default Hero
